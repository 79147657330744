import React, {useState} from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import AdminControls from './AdminControls';
import logo from "../../assets/svg/night/logo.svg";
import logo_coop from "../../assets/svg/logo_coop.svg";

const ADMIN_PANEL_AFTER = 5; // clicks

export default function Header() {

  const [showAdmin, setShowAdmin] = useState(0);

  const openAdminDialog = () => {
    setShowAdmin(prev => prev + 1);
    setTimeout(() => {
      setShowAdmin(prev => {
        return prev < ADMIN_PANEL_AFTER ? 0 : prev;
      })
    }, ADMIN_PANEL_AFTER * 1000);
  }

  return (
    <AppBar position="static">
      <Toolbar style={toolbar}>
        <div onClick={openAdminDialog} style={{ display: 'flex' }}>
          <img alt="Itinera" src={logo} width={"20%"} />
        </div>
        <img alt="COOP Itinera" src={logo_coop} width={"30%"} />
      </Toolbar>
      <AdminControls show={showAdmin >= ADMIN_PANEL_AFTER} onClose={() => setShowAdmin(0)}/>
    </AppBar>
  );
}

const toolbar = {
  justifyContent: "space-between",
  paddingTop: "10px",
  paddingBottom: "10px",
};
