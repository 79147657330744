const de = {
  lang: "Deutsch",
  select_lang: "Wählen Sie Ihre Sprache",
  day: "Tag",
  night: "Nacht",
  confirm: "Bestätigung",
  continue: "Weiter",
  yes_continue: "Ja, weiter",
  start: "Start",
  back: "Back",
  wait: "Warten Sie mal...",
  headset: "Tragen Sie das mitgelieferte Headset, um den Ton richtig zu hören",
  raise_volume: "Erhöhen Sie die Lautstärke des Smartphones",
  sound: "Hören Sie den Ton richtig?",
  wait_location: "Überprüfe das GPS-Signal",
  gps: "Die App versucht herauszufinden, wo Sie sich befinden",
  gps_error: "Es gibt ein Problem mit dem GPS dieses Telefons",
  gps_quality_bad: "NIEDRIGES SIGNAL",
  gps_quality_average: "AKZEPTABLES SIGNAL",
  gps_quality_good: "AUSGEZEICHNETES SIGNAL",
  cookies: "Eine Tour ist bereits im Gange",
  cookies_action: "Möchten Sie fortfahren oder neu starten?",
  cookies_delete: "Neue tour",
  cookies_maintain: "Tour fortsetzen",
  livorno: '"L" wie Livorno',
  unlocking:
    "Verwenden Sie dieses Muster während Ihres Besuchs, um die App bei Bedarf freizuschalten",
  // unlock: 'Entriegeln mit einem "L"',
  unlock: 'Freischaltung bestätigen?',
  unlock_subtitle: 'Das Entsperren des Bildschirms ist für Administratoren reserviert',
  areyouready: "Sind Sie bereit für die Tour?",
  welcome: "WILLKOMMEN",
  map: "Karte",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "Diese App wurde für mobile Geräte entwickelt.",
  screen_locked: "Bildschirm gesperrt",
  swipe_left: "Zum Entsperren nach links wischen",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_soft: "Reset app",
  admin_reset_hard: "Ricomincia tour",
  end_title: "Die Rundfahrt der Fossi ist vorbei.",
  end_message: "Scannen Sie diesen QR-Code mit Ihrem Handy und hinterlassen Sie uns ein Feedback, ein Preis wartet auf Sie!",
  end_reset_hard: "Nuovo giro",
  search_gps: "Suche nach dem GPS-Signal",
  location: "Wählen Sie Ihren Ausgangspunkt",
  pause: "Stoppen",
  restart: "Fortsetzen",
  beforestart:
    "Drücken Sie die Start-Taste, wenn sich das Boot in Bewegung setzt, um das Erlebnis zu beginnen.",
  ispause: "DIE TOUR IST PAUSIERT",
  ispause_restart:
    "Drücken Sie die Play-Taste, um es neu zu starten.",
};

export default de;
