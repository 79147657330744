import audio0url from '../../assets/audio/fr/Punto_00_Darsena_Nuova_FRA_Def.aac';
import audio1url from '../../assets/audio/fr/Punto_01_Porto_Mediceo_FRA_Def.aac';
import audio2url from '../../assets/audio/fr/Punto_02_Fortezza_Vecchia_e_Darsena_Vecchia_FRA_Def.aac';
import audio3url from '../../assets/audio/fr/Punto_03_Monumento_4_Mori_e_Fortezza_Vecchia_FRA_Def.aac';
import audio4url from '../../assets/audio/fr/Punto_04_Quartiere_Venezia_FRA_Def.aac';
import audio5url from '../../assets/audio/fr/Punto_05_Scali_Rosciano_FRA_Def.aac';
import audio6url from '../../assets/audio/fr/Punto_06_Chiesa_e_Convento_dei_Domenicani_FRA_Def.aac';
import audio7url from '../../assets/audio/fr/Punto_07_Il_Pontino_FRA_Def.aac';
import audio8url from '../../assets/audio/fr/Punto_08_Piazza_della_Repubblica_FRA_Def.aac';
import audio9url from '../../assets/audio/fr/Punto_09_Mercato_Centrale_FRA_Def.aac';
import audio10url from '../../assets/audio/fr/Punto_10_Chiesa_degli_Olandesi_FRA_Def.aac';
import audio11url from '../../assets/audio/fr/Punto_11_Palazzi_dell_800_FRA_Def.aac';
import audio12url from '../../assets/audio/fr/Punto_12_Cantina_Palio_Marinaro_FRA_Def.aac';

// punto 0 finocchietti
import audio0_b_url from '../../assets/audio/fr/Punto_00_Finocchietti_FRA_Def.aac';
// punto 04 finocchietti è un finale alternativo (quartiere venezia seconda parte)
import audio4_b_url from '../../assets/audio/fr/Punto_04_Finocchietti_FRA_Def.aac';
// punto 12 finocchietti è una variante di Cantina Canottieri e Palio
import audio12_b_url from '../../assets/audio/fr/Punto_12_Finocchietti_FRA_Def.aac';
// punto welcome
import audioWelcomeUrl from '../../assets/audio/fr/Punto_Benvenuto_FRA_Def.aac';

const fr = {
  audioWelcome: {
    url: audioWelcomeUrl,
    label: 'Benvenuti'
  },
  audio0: {
    url: audio0url,
    label: 'Darsena Nuova'
  },
  audio1: {
    url: audio1url,
    label: 'Porto Mediceo'
  },
  audio2: {
    url: audio2url,
    label: ''
  },
  audio3: {
    url: audio3url,
    label: ''
  },
  audio4: {
    url: audio4url,
    label: ''
  },
  audio5: {
    url: audio5url,
    label: ''
  },
  audio6: {
    url: audio6url,
    label: ''
  },
  audio7: {
    url: audio7url,
    label: ''
  },
  audio8: {
    url: audio8url,
    label: "",
  },
  audio9: {
    url: audio9url,
    label: ''
  },
  audio10: {
    url: audio10url,
    label: ''
  },
  audio11: {
    url: audio11url,
    label: ''
  },
  audio12: {
    url: audio12url,
    label: ''
  },
  audio0_b: {
    url: audio0_b_url,
    label: ''
  },
  audio4_b: {
    url: audio4_b_url,
    label: ''
  },
  audio12_b: {
    url: audio12_b_url,
    label: ''
  }
};

export default fr;