const en = {
  lang: "English",
  select_lang: "Choose your language",
  day: "Day",
  night: "Night",
  confirm: "Confirm",
  continue: "Continue",
  yes_continue: "Yes, continue",
  start: "Start",
  back: "Back",
  wait: "Plase wait...",
  headset: "Please use the provided headset for a better experience",
  raise_volume: "Turn up your device's volume",
  sound: "Can you hear the sound correctly?",
  wait_location: "Verify GPS signal",
  gps: "The app is trying to get your location",
  gps_error: "There's a problem with this phone GPS",
  gps_quality_bad: "POOR SIGNAL",
  gps_quality_average: "ACCEPTABLE SIGNAL",
  gps_quality_good: "EXCELLENT SIGNAL",
  cookies: "There's a tour already in progress",
  cookies_action: "Would you like to continue or to start it again?",
  cookies_delete: "New tour",
  cookies_maintain: "Continue tour",
  livorno: '"L" as Livorno',
  unlocking:
    "If you need to use the app during the visit, unlock it using this code",
  // unlock: 'Unlock with an "L"',
  unlock: 'Confirm unlock?',
  unlock_subtitle: 'Unlocking the screen is for admins only',
  areyouready: "Ready to begin the tour?",
  welcome: "WELCOME",
  map: "Map",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "This App was designed for mobile devices.",
  screen_locked: "Screen locked",
  swipe_left: "Swipe left to unlock",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_soft: "Reset app",
  admin_reset_hard: "Ricomincia tour",
  end_title: "Fossi tour gets to the end.",
  end_message: "Scan this QR code with your phone and leave us feedback, a prize is waiting for you!",
  end_reset_hard: "Nuovo giro",
  search_gps: "Searching for GPS signal",
  location: "Select departure location",
  pause: "Pause",
  restart: "Resume",
  beforestart:
    "Click the 'Start' button when the boat starts to move to begin the tour.",
  ispause: "TOUR IS PAUSED",
  ispause_restart:
    "Click the play button to resume it.",

};

export default en;
