const es = {
  lang: "Español",
  select_lang: "Elija su idioma",
  day: "Día",
  night: "Noche",
  confirm: "Confirmar",
  continue: "Continuar",
  yes_continue: "Sí, continúa",
  start: "Iniciar",
  back: "Atrás",
  wait: "Espera...",
  headset: "Usa los auriculares suministrados para escuchar el sonido correctamente.",
  raise_volume: "Aumenta el volumen del teléfono",
  sound: "¿Estás escuchando el audio correctamente?",
  wait_location: "Comprobar la señal GPS",
  gps: "La aplicación está tratando de averiguar dónde estás",
  gps_error: "Hay un problema con el GPS de este teléfono",
  gps_quality_bad: "SEÑAL BAJA",
  gps_quality_average: "SEÑAL ACEPTABLE",
  gps_quality_good: "SEÑAL EXCELENTE",
  cookies: "Ya hay una visita en curso",
  cookies_action: "¿Te gustaría continuar o empezar de nuevo?",
  cookies_delete: "Nueva visita",
  cookies_maintain: "Continuar visita",
  livorno: '"L" como Livorno',
  unlocking:
    "Si necesitas utilizar la aplicación durante la visita, desbloquéala con este código",
  // unlock: 'Desbloquear con una "L"',
  unlock: '¿Confirmar el desbloqueo?',
  unlock_subtitle: 'El desbloqueo de la pantalla está reservado a los administradores',
  areyouready: "¿Preparado para empezar el recorrido?",
  welcome: "BIENVENIDO",
  map: "Mapa",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "Esta aplicación fue diseñada para dispositivos móviles.",
  screen_locked: "Pantalla bloqueada",
  swipe_left: "Deslizar hacia la izquierda para desbloquear",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_soft: "Reset app",
  admin_reset_hard: "Ricomincia tour",
  end_title: "La visita de los Canales ha terminado.",
  end_message: "Escanea este código QR con tu teléfono y déjanos tu opinión, ¡un premio te está esperando!",
  end_reset_hard: "Nuovo giro",
  search_gps: "Búsqueda de la señal GPS",
  location: "Seleccione su punto de partida",
  pause: "Interrumpir",
  restart: "Reanudar",
  beforestart:
    "Presione el botón 'Iniciar' cuando el barco comience a moverse para comenzar la experiencia.",
    ispause: "EL TOUR ESTÁ EN PAUSA",
    ispause_restart:
      "Presione el botón de reproducción para reiniciarlo.",
};

export default es;
