import React from "react";
import { Fab } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import { useExperience } from "../contexts/experience";

export default function Pause() {

  const { pauseTour, restartTour, pause} = useExperience();

  if (pause) return (
    <Fab
      style={{
        position: "absolute",
        top: "-30px",
        left: "30px",
        zIndex: 9,
      }}
      onClick={restartTour}
    >
      <PlayIcon/>
    </Fab>
  );
  else return (
    <Fab
      color="primary"
      style={{
        position: "absolute",
        top: "-30px",
        left: "30px",
        zIndex: 9,
      }}
      onClick={pauseTour}
    >
      <PauseIcon/>
    </Fab>
  );
}