import React from "react";
import { useTheme } from "../contexts/theme";
import {
  FormGroup,
  Grid,
  Typography,
  Switch,
  IconButton,
} from "@material-ui/core";
import DayIcon from "@material-ui/icons/WbSunny";
import NightIcon from "@material-ui/icons/Nightlight";
import String from "./String";
import palette from "../../config/themes/palette";

export default function ThemeSwitcher() {
  const { theme, toggleTheme } = useTheme();
  return (
    <FormGroup>
      <Grid container alignItems="center" width="100%">
        <Grid item xs={3} align="center">
          <Typography color={theme !== "day" && "textSecondary"}>
            <String s="day" />
          </Typography>
        </Grid>
        <Grid item xs={6} align="center">
          <Switch
            icon={<SwitchIcon theme={theme} />}
            checkedIcon={<SwitchIcon theme={theme} />}
            checked={theme === "night"}
            onChange={toggleTheme}
            color="primary"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme !== "night" && "textSecondary"}>
            <String s="night" />
          </Typography>
        </Grid>
      </Grid>
    </FormGroup>
  );
}

function SwitchIcon({ theme }) {
  return (
    <IconButton
      size="small"
      style={{
        background: theme === "day" ? palette.light_grey : palette.dark_grey,
      }}
    >
      {theme === "day" ? (
        <DayIcon style={{ fontSize: "15px" }} />
      ) : (
        <NightIcon style={{ fontSize: "15px" }} />
      )}
    </IconButton>
  );
}
