import React, { useState, useEffect, useCallback } from "react";
import IdleTimer from 'react-idle-timer';
import Hammer from "react-hammerjs";
import { Box, Dialog, Fab, Typography, Button } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import KeyIcon from '@material-ui/icons/VpnKey';
import Layout from "./Layout";
import Logo from "./Logo";
import String from "./String";
import { useTheme } from "../contexts/theme";

const AUTOLOCK_TIMEOUT = 8 * 1000; // ms
const HIDE_PATTERN_TIMEOUT = 12 * 1000; // ms
const SWIPE_THRESHOLD = 100; // px
const LOCK_BACKDROP_TRANSPARENCY = .35;

export default function Lock() {

  const [locked, setLocked] = useState(false);

  const handleOnIdle = useCallback(() => {
    if (!locked) {
      setLocked(true);
    }
  }, [locked]);

  return (
    <>

      <IdleTimer timeout={AUTOLOCK_TIMEOUT} onIdle={handleOnIdle} debounce={500} />

      {locked ? (
        <Fab
          color="primary"
          style={{
            position: "absolute",
            top: "-30px",
            right: "30px",
            zIndex: 9,
            pointerEvents: "none"
          }}
        >
          {"<<"}<LockIcon/>
        </Fab>
      ) : (
        <Fab
          onClick={() => setLocked(true)}
          style={{
            position: "absolute",
            top: "-30px",
            right: "30px",
            zIndex: 9,
          }}
        >
          <LockIcon />
        </Fab>
      )}

      <LockedScreen locked={locked} setLocked={setLocked} />
    </>
  );
}

function LockedScreen({ locked, setLocked }) {

  const [showPattern, setShowPattern] = useState(false);
  const { theme, styles } = useTheme();

  const dialogBgColor = showPattern ? styles.palette.background.default : `transparent`;
  const dialogBackdropBgColor = `rgba(${styles.palette.background.rgb}, ${LOCK_BACKDROP_TRANSPARENCY})`;

  useEffect(() => {
    if (locked) {
      setShowPattern(false);
    }
  }, [locked]);

  return (
    <Dialog
      fullScreen
      open={locked}
      className={`lock ${theme}`}
      PaperProps={{
        style: {
          backgroundColor: dialogBgColor,
          transition: 'background-color .5s ease-in-out'
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: dialogBackdropBgColor
        }
      }}
    >
      {
        showPattern ?
          <PatternScreen setLocked={setLocked} showPattern={showPattern} setShowPattern={setShowPattern} /> :
          <Swipe setShowPattern={setShowPattern} />
      }
    </Dialog>
  );
}

function Swipe({ setShowPattern }) {

  const handleSwipe = useCallback(() => setShowPattern(true), [setShowPattern]);

  return (
    <Layout
      full
    >
      <Hammer
        onSwipe={handleSwipe}
        direction="DIRECTION_LEFT"
        options={{
          recognizers: {
            swipe: {
              threshold: SWIPE_THRESHOLD
            }
          }
        }}
      >
        <div
          id="swipe-area"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }} />
      </Hammer>
    </Layout>
  );
}

function PatternScreen({ setLocked, showPattern, setShowPattern }) {

  const handlePatternIdle = useCallback(() => {
    if (showPattern) {
      setShowPattern(false);
    }
  }, [setShowPattern, showPattern]);

  return (
    <>
      <IdleTimer timeout={HIDE_PATTERN_TIMEOUT} onIdle={handlePatternIdle} debounce={500} />

      <Layout
        top={
          <Box mt={7} textAlign="center">
            <Logo />
          </Box>
        }
        bottom={
          <Button
            color="primary"
            onClick={() => setShowPattern(false)}
          >
            <String s="back" />
          </Button>
        }
      >
        <Box textAlign="center" style={{ position: 'relative' }}>
          <Typography variant="title" mb={2}>
            <String s="unlock" />
          </Typography>

          <Fab
            color="primary"
            onClick={(e) => {
              setLocked(false)
            }}
          >
            <KeyIcon />
          </Fab>
        </Box>
      </Layout>
    </>
  );
}