import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Button,
  Grid,
  Stack,
} from "@material-ui/core";
import Layout from "../components/Layout";
import Routes from "../components/Routes";
import Logo from "../components/Logo";
import ThemeSwitcher from "../components/ThemeSwitcher";
import { useLanguage } from "../contexts/language";
import String from "../components/String";
import Footer from "../components/Footer";
import AppVersion from "../components/AppVersion";
import BackIcon from "@material-ui/icons/ArrowRightAlt";
import Image from "../components/Image";
import test_sound from "../../assets/audio/test_sound.mp3";
import { useExperience } from "../contexts/experience";

const steps = [
  { path: "/boarding/1", component: Language },
  { path: "/boarding/2", component: TourSelection },
  { path: "/boarding/3", component: Headphones },
  { path: "/boarding/4", component: Audio },
];

export default function Boarding() {
  
  const { language } = useLanguage();
  const views = boardingViews(steps, language);
  return (
    <Layout
      full
      textured
      top={
        <Box mt={"5vh"} textAlign="center">
          <Logo />
        </Box>
      }
      bottom={
        <Footer>
          <PoweredBy />
        </Footer>
      }
    >
      <AppVersion />
      <Routes views={views} />
    </Layout>
  );
}

function Language(p) {
  const { language, availableLanguages, setLanguage } = useLanguage();
  return (
    <BoardingStep
      title={<String s="select_lang" />}
      form={
        <FormControl variant="outlined" fullWidth>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {availableLanguages.map((l) => (
              <MenuItem value={l} key={l}>
                <String s="lang" l={l} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      action={<String s="confirm" />}
      themeSwitcher
      {...p}
    />
  );
}

function Headphones(p) {
  return (
    <BoardingStep
      title={<String s="headset" />}
      image={"headphones"}
      action={<String s="continue" />}
      {...p}
    />
  );
}

function Audio(p) {
  return (
    <>
      <BoardingStep
        pretitle={<String s="raise_volume" />}
        title={<String s="sound" />}
        image={"phone"}
        action={<String s="yes_continue" />}
        {...p}
      />
      <audio src={test_sound} autoPlay loop style={{ display: "none" }} />
    </>
  );
}

function TourSelection(p) {

  const { 
    getExpCookie,
    pattern,
    patterns,
    selectTour,
    resetTour
  } = useExperience();

  const cookie = getExpCookie();
  const [hasRunningExp, setHasRunningExp] = useState(!!cookie);
  const tours = patterns.map(e => ({value: e.id, label: e.label}))

  const resetExp = (e) => {
    e.preventDefault();
    resetTour();
    console.log("Cookies deleted!");
    setHasRunningExp(false);
  };

  if (hasRunningExp) return (
    <BoardingStep
      title={<String s="cookies" />}
      text={<String s="cookies_action" />}
      action={<String s="cookies_maintain" />}
      auxAction={<String s="cookies_delete" />}
      auxActionClick={resetExp}
      {...p}
    />
  );

  return (
    <BoardingStep
      title={<String s="location" />}
      action={<String s="confirm" />}
      disabled={!pattern}
      selection={tours}
      selected={pattern && pattern.id}
      onSelect={selectTour}
      {...p}
    />
  );
}

const boardingViews = (steps, language) =>
  steps.map(({ component: Component, path }, i) => {
    const next = steps[i + 1] ? steps[i + 1].path :  `/tour?lang=${language}`;
    const back = steps[i - 1] ? steps[i - 1].path : null;
    return {
      path,
      render: (props) => (
        <Component
          steps={steps}
          step={i + 1}
          next={next}
          back={back}
          {...props}
        />
      ),
      exact: true,
    };
  });

  function BoardingStep({
    title,
    pretitle,
    text,
    image,
    media,
    form,
    action,
    auxAction,
    auxActionClick,
    selection,
    selected,
    onSelect,
    next,
    disabled,
    themeSwitcher,
    ...p
  }) {
    return (
      <Layout justify="space-between">
        <Stack flexGrow={1} justifyContent="center" width="90%">
          <Stack textAlign="center" spacing={2} mb={"3vh"}>
            {pretitle && (
              <Typography variant={"pretitle"}>
                {pretitle}
              </Typography>
            )}
            <Typography variant={"title"}>
              {title}
            </Typography>
            {text && (
              <Typography variant={"text"}>
                {text}
              </Typography>
            )}
            {media}
            {(image || form) && <Box>
              {image && <Image i={image} style={{ height: "15vh" }} />}
              {form}
            </Box>}
            {selection && selection.length > 0 && onSelect ?
              <Stack spacing={1}>
              {selection.map((item, idx) =>
                <Button
                  color={selected === item.value ? "selected" : "secondary"}
                  variant="contained"
                  style={{paddingLeft: 1, paddingRight: 1}}
                  fullWidth
                  onClick={() => onSelect(item.value)}
                  key={idx}
                >
                  {item.label}
                </Button>)}
              </Stack> : null}
            {action && <Box mt={3}>
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to={next}
                disabled={disabled}
                fullWidth
              >
                {action}
              </Button>
            </Box>}
            {auxAction && auxActionClick && <Box>
              <Button
                color="secondary"
                variant="contained"
                mt={2}
                disabled={disabled}
                fullWidth
                onClick={(e) => auxActionClick(e)}
              >
                {auxAction}
              </Button>
            </Box>}
          </Stack>
        </Stack>
        <Box>
          <StepNav {...p} />
          <ThemeSwitcher />
        </Box>
      </Layout>
    );
  }

function StepNav({ step, steps, back }) {
  return (
    <Grid container alignItems="center" height="50px" width="40vw">
      <Grid item xs={3} align="center">
        {back && (
          <Link to={back}>
            <Typography fontSize={"4vw"} color="textSecondary">
              <BackIcon
                style={{ transform: "rotate(-180deg)", fontSize: "40px" }}
              />
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid item xs={6} align="center">
        <Typography fontSize={"6vw"} color="textSecondary">
          {step}/{steps.length}
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

function PoweredBy() {
  return <Typography variant="text">Powered by mezzoforte</Typography>;
}
