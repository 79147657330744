import React, { useState } from "react";
import languages, { default_language } from "../../config/languages";

import alarm_it from "../../assets/audio/it/lock_alert.mp3";
import alarm_en from "../../assets/audio/en/lock_alert.mp3";
import alarm_fr from "../../assets/audio/fr/lock_alert.mp3";
import alarm_de from "../../assets/audio/de/lock_alert.mp3";
import alarm_es from "../../assets/audio/es/lock_alert.mp3";

// import welcome_it from "../../assets/audio/it/Punto_Benvenuto_ITA_Def.aac";
// import welcome_en from "../../assets/audio/en/Punto_Benvenuto_ENG_Def.aac";
// import welcome_fr from "../../assets/audio/fr/Punto_Benvenuto_FRA_Def.aac";
// import welcome_de from "../../assets/audio/de/Punto_Benvenuto_DEU_Def.aac";
// import welcome_es from "../../assets/audio/es/Punto_Benvenuto_SPA_Def.aac";

const ALARM_SOUNDS = {
  it: alarm_it,
  en: alarm_en,
  fr: alarm_fr,
  de: alarm_de,
  es: alarm_es
};

// const WELCOME_MESSAGES = {
//   it: welcome_it,
//   en: welcome_en,
//   fr: welcome_fr,
//   de: welcome_de,
//   es: welcome_es
// };

const LanguageContext = React.createContext(null);

export default function Language({ children }) {
  const [language, setLanguage] = useState(default_language);
  const availableLanguages = Object.keys(languages);
  const content = (key, l) => languages[l || language][key];
  const alarmSound = ALARM_SOUNDS[language];
  // const welcomeMessage = WELCOME_MESSAGES[language];

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, availableLanguages, content, alarmSound }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageContext);
