import React, { useState } from "react";
import { Box, Dialog, Typography, Button } from "@material-ui/core";
import Layout from "./Layout";
import Logo from "./Logo";
import String from "./String";
import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience";
import qrCode from "../../assets/qr.png";

const FORCE_SHOW_RESET_BUTTON = false; // if true reset button is always visible
const RESET_BUTTON_AFTER = 5;

export default function End({show}) {
  const { theme } = useTheme();
  const { resetTour } = useExperience();

  const hardReset = () => {
    resetTour();
    window.location = "/";
  }

  const [showResetButton, setShowResetButton] = useState(0);
  const openResetButton = () => {
    if (!FORCE_SHOW_RESET_BUTTON) {
      setShowResetButton(prev => prev + 1);
      setTimeout(() => {
        setShowResetButton(prev => {
          return prev < RESET_BUTTON_AFTER ? 0 : prev;
        })
      }, RESET_BUTTON_AFTER * 1000);
    }
  }

  return (
    <Dialog fullScreen open={show} className={"lock " + theme}>
      <Layout
        top={
          <Box mt={7} textAlign="center">
            <div onClick={openResetButton}>
            <Logo />
            </div>
          </Box>
        }
      >
        <Box textAlign="center" style={{ position: 'relative' }}>
          <Typography variant="title" mb={2}>
            <String s="end_title" />
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            <String s="end_message" />
          </Typography>
          <Box component="img"
          src={qrCode}
          width="100%"
          />
          {FORCE_SHOW_RESET_BUTTON || showResetButton >= RESET_BUTTON_AFTER ? 
            <Box mb={2}>
              <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={hardReset}><String s="end_reset_hard" /></Button>
            </Box>: null}
        </Box>
      </Layout>
    </Dialog>
  );
}