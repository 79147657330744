const it = {
  lang: "Italiano",
  select_lang: "Scegli la lingua",
  day: "Giorno",
  night: "Notte",
  confirm: "Conferma",
  continue: "Continua",
  yes_continue: "Si, continua",
  start: "Inizia",
  back: "Indietro",
  wait: "Attendi...",
  headset: "Indossa le cuffie in dotazione per sentire l'audio correttamente",
  raise_volume: "Aumenta il volume dello smartphone",
  sound: "Stai sentendo l'audio correttamente?",
  wait_location: "Verifica il segnale GPS",
  gps: "L'app sta cercando di capire dove ti trovi",
  gps_error: "C'è un problema con il GPS di questo telefono",
  gps_quality_bad: "SEGNALE SCARSO",
  gps_quality_average: "SEGNALE ACCETTABILE",
  gps_quality_good: "SEGNALE OTTIMO",
  cookies: "Esiste un giro salvato",
  cookies_action: "Vuoi riprendere il giro o iniziarne uno nuovo?",
  cookies_delete: "Inizia nuovo giro",
  cookies_maintain: "Riprendi il giro",
  livorno: '"L" come Livorno',
  unlocking:
    "Durante la visita, se devi usare l'app, sbloccala con questa sequenza",
  // unlock: 'Sblocca con il segno "L"',
  unlock: 'Confermare lo sblocco?',
  unlock_subtitle: 'Lo sblocco dello schermo è riservato agli admin',
  areyouready: "Sei pronto per iniziare il tour dei fossi?",
  welcome: "BENVENUTO",
  map: "Mappa",
  code: "FAQ",
  insert_code:
    "Inserisci il numero corrispondente alle TAPPE DEL VIAGGIO che trovi sul depliant",
  faq: [
    {
      q: "Problemi con la connessione?",
      a: "Se hai problemi con la connessione prova a...",
    },
    { q: "Altra FAQ", a: "Se hai problemi con la connessione prova a..." },
  ],
  mobile_only: "Questa App è  progettata per dispositivi mobili",
  screen_locked: "Schermata bloccata",
  swipe_left: "Scorri verso sinistra per sbloccare",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_soft: "Reset app",
  admin_reset_hard: "Ricomincia tour",
  end_title: "Il tour dei Fossi è terminato.",
  end_message: "Inquadra questo codice QR con il tuo telefono e lasciaci un feedback, un premio ti aspetta!",
  end_reset_hard: "Ricomincia tour",
  search_gps: "Ricerca GPS in corso",
  location: "Seleziona il punto di partenza",
  pause: "Interrompi",
  restart: "Riprendi",
  beforestart:
    "Premi il pulsante 'Inizia' quando la barca inizia a muoversi per far partire l'esperienza.",
  ispause: "IL TOUR È IN PAUSA",
  ispause_restart:
    "Premi il tasto play per riprenderlo.",
};

export default it;
