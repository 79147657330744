const fr = {
  lang: "Français",
  select_lang: "Choisissez votre langue",
  day: "Jour",
  night: "Nuit",
  confirm: "Confirmer",
  continue: "Continuer",
  yes_continue: "Oui, continuez",
  start: "Start",
  back: "Retour",
  wait: "Veuillez patienter...",
  headset: "Portez les écouteurs fournis pour entendre correctement le son",
  raise_volume: "Augmenter le volume du smartphone",
  sound: "Entendez-vous correctement l'audio?",
  wait_location: "Vérifier le signal GPS",
  gps: "L'application essaie de déterminer où vous vous trouvez",
  gps_error: "Il y a un problème avec le GPS de ce téléphone",
  gps_quality_bad: "SIGNAL FAIBLE",
  gps_quality_average: "SIGNAL ACCEPTABLE",
  gps_quality_good: "EXCELLENT SIGNAL",
  cookies: "Vous avez un tour sauvegardé",
  cookies_action: "Souhaitez-vous le reprendre ou en commencer un autre?",
  cookies_delete: "Début du nouveau tour",
  cookies_maintain: "Reprendre mon tour",
  livorno: '"L" comme Livorno',
  unlocking:
    "Pendant la visite, si vous souhaitez parcourir l'application, utilisez cette séquence pour la déverrouiller",
  // unlock: 'Déverrouiller avec un "L"',
  unlock: 'Confirmer le déverrouillage?',
  unlock_subtitle: 'Le déverrouillage de l\'écran est réservé aux admins.',
  areyouready: "Prêt à commencer la visite?",
  welcome: "BIENVENUE",
  map: "Carte",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "Cette application a été conçue pour les appareils mobiles.",
  screen_locked: "Écran verrouillé",
  swipe_left: "Glisser vers la gauche pour déverrouiller",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_soft: "Reset app",
  admin_reset_hard: "Ricomincia tour",
  end_title: "Le tour des Fossi est terminée.",
  end_message: "Flashez le QR code avec votre smartphone et laissez un avis, un cadeau vous attend!",
  end_reset_hard: "Nuovo giro",
  search_gps: "Recherche du signal GPS",
  location: "Sélectionnez le lieu de départ",
  pause: "Pause",
  restart: "Reprendre",
  beforestart:
    "Cliquez sur le bouton 'Start' lorsque le bateau commence à bouger pour commencer la visite.",
  ispause: "LA VISITE EST EN PAUSE",
  ispause_restart:
    "Cliquez sur le bouton de lecture pour le reprendre.",
};

export default fr;
