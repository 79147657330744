import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import audio from "../../config/audio";
import experience from "../../config/experience";
import { setCookie, getCookie, deleteCookie } from "../utils/cookies";
import { getTimestamp } from "../utils/utils";
import { useLanguage } from "./language";

const NOVI_LENA_LAST = 14;
const FINOCCHIETTI_LAST = 13;
export const COOKIE_NAME = 'fossi-config';

const ExperienceContext = React.createContext(null);

export default function Experience({ children }) {

  const { language } = useLanguage();

  const interval = useRef();
  const startTime = useRef();

  const [active, setActive] = useState();
  const [end, setEnd] = useState(false);
  const [pause, setPause] = useState();
  const [started, setStarted] = useState(false);
  const [pattern, setPattern] = useState();
  const [visited, setVisited] = useState([]);
  const [timer, setTimer] = useState();

  const audioCfg = useMemo(() => audio(language), [language]);

  const getExpCookie = () => {
    const cookie = getCookie(COOKIE_NAME);
    if (!cookie) return undefined;
    try {
      return JSON.parse(getCookie(COOKIE_NAME));
    } catch {
      console.error("Failed to parse cookie");
      return undefined;
    }
  };
  const deleteExpCookie = () => deleteCookie(COOKIE_NAME);
  const setExpCookie = useCallback(() => {
    const cookie = {
      pattern: pattern.id,
      visited: visited,
      startTime: startTime.current,
      pauseTime: pause
    }
    setCookie(COOKIE_NAME, JSON.stringify(cookie));
  }, [pattern, visited, startTime, pause]);

  const isNoviLena = () => (pattern && pattern.id === "novilena");
  const isFinocchietti = () => (pattern && pattern.id === "finocchietti");

  const selectTour = (patternId) => {
    const pattern = experience.patterns.find(p => p.id === patternId);
    if (!pattern) return;
    setPattern(pattern);
  }

  const resetTour = () => {
    setPattern(undefined);
    deleteExpCookie();
  };

  const startTour = useCallback(() => {
    setStarted(true);
    const now = new Date();

    // check for experience already in progress
    const cookie = getExpCookie();
    if (cookie) {
      if (cookie.visited) setVisited(cookie.visited);
      else setVisited([]);
      if (cookie.startTime) startTime.current = cookie.startTime;
      else startTime.current = getTimestamp(now);
      if (cookie.pauseTime) {
        setPause(cookie.pauseTime);
        return;
      }
    } else {
      setVisited([]);
      startTime.current = getTimestamp(now);
    }

    interval.current && clearInterval(interval.current);
    interval.current = setInterval(() => {
      if (!startTime.current) return;
      const now = new Date();
      setTimer(getTimestamp(now) - startTime.current);
    }, 1000);
  }, [startTime]);

  const pauseTour = useCallback(() => {
    const now = new Date();
    setPause(getTimestamp(now));
    interval.current && clearInterval(interval.current);
  }, []);

  const restartTour = useCallback(() => {
    const now = new Date();
    startTime.current = startTime.current + (getTimestamp(now) - pause);
    setPause(undefined);

    interval.current && clearInterval(interval.current);
    interval.current = setInterval(() => {
      if (!startTime.current) return;
      const now = new Date();
      setTimer(getTimestamp(now) - startTime.current);
    }, 1000);
  }, [pause]);

  const endSpot = useCallback(() => {
    if (!active) return;

    // tour end interception
    const isTourEnd = (isNoviLena() && active.id >= NOVI_LENA_LAST)
      || (isFinocchietti() && active.id >= FINOCCHIETTI_LAST)
    if (isTourEnd) {
      interval.current && clearInterval(interval.current);
      deleteExpCookie();
      setTimeout(() => {
        setEnd(true);
      }, 5000);
    }

    setVisited(visited => [...visited, active.id]);
    setActive(undefined);
  // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    const cookie = getExpCookie();
    if (cookie) {
      const pattern = experience.patterns.find(p => p.id === cookie.pattern);
      if (pattern) setPattern(pattern);
      else {
        // error in stored cookie, reset app
        console.error("Stored pattern doesn't exist");
        deleteExpCookie();
        window.location = "/";
      }
    }
    return () => interval.current && clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (timer === undefined) return
    if (!pattern) return
    if (pause) return

    const toVisit = pattern.spots.filter((spot, idx) => {
      if (visited.includes(spot.id)) return undefined;
      if (timer < spot.after) return spot;
      const next = pattern.spots[idx + 1];
      if (!next) return spot;
      const notAfter = next.after - spot.duration;
      if (timer < notAfter) return spot;
      else {
        setVisited(visited => [...visited, spot.id]);
        return undefined;
      }
    });
    if (!toVisit || toVisit.length === 0) return;
    const toStart = toVisit.find(spot => timer > spot.after);
    if (!toStart) return;
    const audio = audioCfg && audioCfg.sounds.find(s => s.id === toVisit[0].audio);
    if (!audio) {
      // audio not found
      console.error("audio not found", toVisit[0]);
      setVisited(visited => [...visited, toVisit[0].id]);
      return;
    }

    // already playing
    if (active) {
      if (toStart.id !== active.id) endSpot();
    } else setActive({
      ...toStart,
      audio
    });
  // eslint-disable-next-line
  }, [timer]);

  useEffect(() => {
    if (visited && startTime.current && pattern) setExpCookie();
  }, [visited, startTime, pattern, pause, setExpCookie]);

  const patterns = experience.patterns;
  const spots = pattern && pattern.spots;

  return (
    <ExperienceContext.Provider
      value={{
        spots,
        active,
        end,
        patterns,
        pattern,
        timer,
        pause,
        started,
        endSpot,
        isNoviLena,
        isFinocchietti,
        getExpCookie,
        startTour,
        selectTour,
        pauseTour,
        restartTour,
        resetTour
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);