import it from "./it.js";
import en from "./en.js";
import fr from "./fr.js";
import de from "./de.js";
import es from "./es.js";

const languages = {
  it,
  en,
  fr,
  de,
  es
};

export default languages;

const parseUrlParams = url => {
	const params = {};
	const parser = document.createElement('a');
	parser.href = url;
	const query = parser.search.substring(1);
	const vars = query.split('&');
  vars.forEach(el => {
    const pair = el.split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  });
	return params;
};

export const default_language = parseUrlParams(window.location.href).lang || "it";
