export const getTimestamp = (date) => {
  const d = new Date(date);
  return d.getTime()/1000;
};

export const getDate = (timestamp) => {
  return new Date(timestamp*1000)
}

export const formatTimer = (t) => {
  if (t > 0) {
    const o = new Date(0);
    const p = new Date(t * 1000);
    const array = new Date(p.getTime() - o.getTime())
      .toISOString()
      .split('T')[1]
      .split('Z')[0]
      .split(':');
    let hours
    try {
      hours = parseInt(array[0], 10)
    } catch {
      // do nothing
    }
    return !hours || hours <= 0 ? `${array[1]}:${array[2].split('.')[0]}` : `${hours}:${array[1]}:${array[2].split('.')[0]}`
  }
};