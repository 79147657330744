import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Backdrop,
  Stack
} from "@material-ui/core";
import NoSleep from "nosleep.js";
import Layout from "../components/Layout";
import String from "../components/String";
import ThemeSwitcher from "../components/ThemeSwitcher";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";
import Lock from "../components/Lock";
import { useLanguage } from "../contexts/language";
import { useExperience } from "../contexts/experience";
import useVisibility from "../hooks/useVisibility";
import { formatTimer } from "../utils/utils";
import End from "../components/End";
import Pause from "../components/Pause";

const noSleep = new NoSleep();

export default function Tour() {

  const { alarmSound } = useLanguage();
  const isVisible = useVisibility();
  const { 
    isNoviLena, isFinocchietti, 
    timer,
    pause,
    startTour,
    started,
    end
  } = useExperience();

  const getTourLabel = useCallback(() => {
    const finocchietti = isFinocchietti();
    const noviLena = isNoviLena();
    if (finocchietti && !noviLena) return "Tour Finocchietti";
    if (noviLena && !finocchietti) return "Tour Novi Lena";
  }, [isFinocchietti, isNoviLena]);

  useEffect(() => {
    // prevent pull-down-to-refresh
    document.body.classList.add('no-pull-refresh');

    return () => {
      document.body.classList.remove('no-pull-refresh');
      noSleep.disable();
    }
  }, []);

  const label = `${getTourLabel() || "Tour"}${started && timer ? " | " + formatTimer(timer) || "00:00" : ""}`;

  return (
    <Layout
      full
      top={<Header />}
      bottom={
        <Footer>
          <ThemeSwitcher />
          {started && <Lock />}
          {started && <Pause />}
        </Footer>
      }
    >
      <Stack color="primary" width="100%" alignItems="center">
        <Typography py={2} textAlign="center"
          variant="pretitle"
        >
            {label.toUpperCase()}
        </Typography>
      </Stack>
      <Layout full>
        <Map />
        <Overlay started={started} pause={!!pause} startTour={startTour}/>
        <End show={end} />
      </Layout>
      {started && !isVisible && (
        <audio src={alarmSound} autoPlay loop style={{ display: "none" }} />
      )}
    </Layout>
  );
}

function Overlay({ started, pause, startTour }) {
  return (
    <Backdrop style={{ position: "absolute", zIndex: 9 }} open={(!started || pause)}>
      <Layout justify="center">
        <Box textAlign="center" ml={4} mr={4}>
          {pause ? (
            <>
              <Typography variant="title" mb={2}>
                <String s="ispause" />
              </Typography>
              <Typography variant="text" mb={3}>
                <String s="ispause_restart" />
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="title" mb={2}>
                <String s="areyouready" />
              </Typography>
              <Typography variant="text" mb={3}>
                <String s="beforestart" />
              </Typography>
            </>
          )}
          {!started && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                noSleep.enable();
                startTour();
              }}
            >
              <String s="start" />
            </Button>
          )}
        </Box>
      </Layout>
    </Backdrop>
  );
}
