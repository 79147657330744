import React, { useEffect, useState } from "react";
import { Box, Dialog, Typography, Button } from "@material-ui/core";
import Layout from "./Layout";
import Logo from "./Logo";
import String from "./String";
import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience";
import test_sound from "../../assets/audio/test_sound.mp3";

export default function AdminControls({ show, onClose }) {
  const { theme } = useTheme();
  const { resetTour } = useExperience();
  
  const [test, setTest] = useState(false);

  const softReset = () => {
    window.location.reload(false);
  }

  const hardReset = () => {
    resetTour();
    window.location = "/";
  }

  useEffect(() => {
    setTest(false);
  }, [show]);

  return (
    <Dialog fullScreen open={show} className={"lock " + theme}>
      <Layout
        top={
          <Box mt={7} textAlign="center">
            <Logo />
          </Box>
        }
      >
        {test && <audio src={test_sound} autoPlay loop style={{ display: "none" }} />}
        <Box textAlign="center" style={{ position: 'relative' }}>
          <Typography variant="title" mb={2}>
            <String s="admin_title" />
          </Typography>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={softReset}>
              <String s="admin_reset_soft" />
            </Button>
          </Box>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={hardReset}>
              <String s="admin_reset_hard" />
            </Button>
          </Box>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth 
            onClick={() => setTest(test => !test)}>
              <String s="admin_test" />
            </Button>
          </Box>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={onClose}>
              <String s="admin_close" />
            </Button>
          </Box>
        </Box>
      </Layout>
    </Dialog>
  );
}