import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useExperience } from "../contexts/experience";
import { Howl } from "howler";
import { formatTimer } from "../utils/utils";

export default function Player({ spot }) {

  const { active, endSpot } = useExperience();

  const [ progress, setProgress] = useState(0);
  const [ audio, setAudio ] = useState();

  const duration = audio ? audio.duration() : 0;
  const perc = audio ? (progress / duration) * 100 : 0;
  const elapsed = audio && formatTimer(progress);

  useEffect(() => {
    const seek = setInterval(() => {
      const p = audio && audio.seek();
      setProgress(p);
    }, 100);
    return () => {
      clearInterval(seek);
      if (audio) {
        audio.playing() && audio.stop();
        audio.unload();
      }
    }
  }, [audio]);

  useEffect(() => {
    if (active && active.audio && !audio) {
      const howl = new Howl({
        src: [active.audio.url],
        html5: true,
        autoplay: true,
        loop: false,
        volume: 1,
        onload: () => setAudio(howl),
        onstop: endSpot,
        onend: endSpot
      });

      // @howlerjs github issues: https://github.com/goldfire/howler.js/issues/1262#issuecomment-576708079
      // link audio node to detect audio paused by the OS
      const node = howl._sounds[0]._node;
      node.onpause = function () {
        let sound;
        for (let s = 0; s < howl._sounds.length; s++) {
          if (howl._sounds[s]._node === this) {
            sound = howl._sounds[s];
          }
        }
        if (!sound) return;
        sound._paused = true;
        sound._seek = this.currentTime;
      }.bind(node);
    }
  }, [active, endSpot, audio]);

  // force audio always playing (there's no playback control)
  active && audio && audio.state() === "loaded" && !audio.playing() && audio.play();

  return (
    <Card style={{ textAlign: "center" }}>
      <Box mb={2} mx={2}>
        {/* <Avatar style={labelStyle}>{active.id || 1}</Avatar> */}
        <Typography variant="h5" mt={1}>
          {spot.label}
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid
          container
          justify="space-around"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <IconButton
              style={btnStyle}
              onClick={() => audio && audio.play()}
            >
              <Box style={countDownStyle}>
                <Typography fontSize={"5vw"} fontWeight="bold">
                  {elapsed || "00:00"}
                </Typography>
              </Box>
              <CircularProgress
                variant="determinate"
                value={Math.floor(perc)}
                style={progressStyle}
                thickness={2}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

const btnStyle = {
  position: "relative",
  border: "2px solid",
  height: "22vw",
  width: "22vw",
};
const countDownStyle = {
  position: "absolute",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
};
const progressStyle = { position: "absolute", height: "107%", width: "107%" };
// const labelStyle = {
//   background: palette.red,
//   color: palette.white,
//   height: "20px",
//   width: "20px",
//   margin: "auto",
//   fontSize: "12px",
//   fontWeight: "bold",
// };
