import React from "react";
import ReactDOM from "react-dom";
import App from "./ui";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {
  Offline as OfflineIntegration,
  CaptureConsole as CaptureConsoleIntegration
} from "@sentry/integrations";

Sentry.init({
  dsn: "https://456845a81786440a879b5a258947f9b0@o1100855.ingest.sentry.io/6126319",
  integrations: [
    new Integrations.BrowserTracing(),
    new OfflineIntegration({ maxStoredEvents: 30 }),
    new CaptureConsoleIntegration({ levels: ['warn', 'error', 'debug', 'assert'] })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("fs-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
