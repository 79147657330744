const experience = {
  patterns: [
    {
      id: "novilena",
      label: "Scali Novi Lena",
      spots: [
        {
          id: 1,
          label: "Benvenuti",
          audio: "aWelcome",
          lon: 10.303650,
          lat: 43.545736,
          after: 0, // seconds
          duration: 83 // seconds
        },
        {
          id: 2,
          label: "Darsena Nuova",
          audio: "a0a",
          lon: 10.302673,
          lat: 43.546584,
          after: 90, // seconds
          duration: 31 // seconds
        },
        {
          id: 3,
          label: "Porto Mediceo",
          audio: "a01",
          lon: 10.300235,
          lat: 43.547615,
          after: 180, // seconds
          duration: 181 // seconds
        },
        {
          id: 4,
          label: "Fortezza Vecchia, Darsena Vecchia",
          audio: "a02",
          lon: 10.302153,
          lat: 43.551338,
          after: 420, // seconds
          duration: 72 // seconds
        },
        {
          id: 5,
          label: "Monumento 4 Mori, Fortezza Vecchia",
          audio: "a03",
          lon: 10.303764,
          lat: 43.549586,
          after: 566, // seconds
          duration: 146 // seconds
        },
        {
          id: 6,
          label: "Quartiere Venezia",
          audio: "a04",
          lon: 10.303764,
          lat: 43.552719,
          after: 776, // seconds
          duration: 98 // seconds
        },
        {
          id: 7,
          label: "Scali Rosciano",
          audio: "a05",
          lon: 10.306709,
          lat: 43.553597,
          after: 906, // seconds
          duration: 94 // seconds
        },
        {
          id: 8,
          label: "Chiesa e Convento dei Domenicani, Fortezza Nuova",
          audio: "a06",
          lon: 10.308133,
          lat: 43.554586,
          after: 1036, // seconds
          duration: 127 // seconds
        },
        {
          id: 9,
          label: "Il Pontino",
          audio: "a07",
          lon: 10.310403,
          lat: 43.555447,
          after: 1186, // seconds
          duration: 177 // seconds
        },
        {
          id: 10,
          label: "Piazza della Repubblica",
          audio: "a08",
          lon: 10.313111,
          lat: 43.553422,
          after: 1386, // seconds
          duration: 231 // seconds
        },
        {
          id: 11,
          label: "Mercato Centrale",
          audio: "a09",
          lon: 10.314230,
          lat: 43.550570,
          after: 1636, // seconds
          duration: 150 // seconds
        },
        {
          id: 12,
          label: "Chiesa degli Olandesi",
          audio: "a10",
          lon: 10.312497,
          lat: 43.548887,
          after: 1796, // seconds
          duration: 111 // seconds
        },
        {
          id: 13,
          label: "I Palazzi dell'800",
          audio: "a11",
          lon: 10.310240,
          lat: 43.547415,
          after: 1936, // seconds
          duration: 85 // seconds
        },
        {
          id: 14,
          label: "Cantina Canottieri Livornesi, Palio Marinaro",
          audio: "a12",
          lon: 10.305466,
          lat: 43.547520,
          after: 2056, // seconds
          duration: 73 // seconds
        }
      ]
    },
    {
      id: "finocchietti",
      label: "Scali Finocchietti",
      spots: [
        {
          id: 1,
          label: "Benvenuti",
          audio: "a0b",
          lon: 10.307494,
          lat: 43.553084,
          after: 0, // seconds
          duration: 128 // seconds
        },
        {
          id: 2,
          label: "Scali Rosciano",
          audio: "a05",
          lon: 10.306709,
          lat: 43.553597,
          after: 140, // seconds
          duration: 94 // seconds
        },
        {
          id: 3,
          label: "Chiesa e Convento dei Domenicani, Fortezza Nuova",
          audio: "a06",
          lon: 10.308133,
          lat: 43.554586,
          after: 270, // seconds
          duration: 127 // seconds
        },
        {
          id: 4,
          label: "Il Pontino",
          audio: "a07",
          lon: 10.310403,
          lat: 43.555447,
          after: 420, // seconds
          duration: 177 // seconds
        },
        {
          id: 5,
          label: "Piazza della Repubblica",
          audio: "a08",
          lon: 10.313111,
          lat: 43.553422,
          after: 620, // seconds
          duration: 231 // seconds
        },
        {
          id: 6,
          label: "Mercato Centrale",
          audio: "a09",
          lon: 10.314230,
          lat: 43.550570,
          after: 870, // seconds
          duration: 150 // seconds
        },
        {
          id: 7,
          label: "Chiesa degli Olandesi",
          audio: "a10",
          lon: 10.312497,
          lat: 43.548887,
          after: 1030, // seconds
          duration: 111 // seconds
        },
        {
          id: 8,
          label: "I Palazzi dell'800",
          audio: "a11",
          lon: 10.310240,
          lat: 43.547415,
          after: 1170, // seconds
          duration: 85 // seconds
        },
        {
          id: 9,
          label: "Cantina Canottieri Livornesi, Palio Marinaro",
          audio: "a12b",
          lon: 10.305466,
          lat: 43.547520,
          after: 1290, // seconds
          duration: 98 // seconds
        },
        {
          id: 10,
          label: "Porto Mediceo",
          audio: "a01",
          lon: 10.300235,
          lat: 43.547615,
          after: 1690, // seconds
          duration: 181 // seconds
        },
        {
          id: 11,
          label: "Fortezza Vecchia, Darsena Vecchia",
          audio: "a02",
          lon: 10.302153,
          lat: 43.551338,
          after: 1930, // seconds
          duration: 72 // seconds
        },
        {
          id: 12,
          label: "Monumento 4 Mori, Fortezza Vecchia",
          audio: "a03",
          lon: 10.303764,
          lat: 43.549586,
          after: 2076, // seconds
          duration: 166 // seconds
        },
        {
          id: 13,
          label: "Quartiere Venezia - Parte 2",
          audio: "a04b",
          lon: 10.303764,
          lat: 43.552719,
          after: 2342, // seconds
          duration: 95 // seconds
        },
      ]
    }
  ]
};

export default experience;
