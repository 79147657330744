import React, { useEffect } from "react";
import { MapContainer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.offline";
import Player from "../components/Player";
import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience";
import { STADIA_MAPS_API_KEY, PRODUCTION_URL } from "../../config/map/index";

import marker_day from "../../assets/svg/day/marker.svg";
import marker_day_active from "../../assets/svg/day/marker_active.svg";
import marker_night from "../../assets/svg/night/marker.svg";
import marker_night_active from "../../assets/svg/night/marker_active.svg";

export default function Map() {

  const { theme } = useTheme();
  const { spots, active } = useExperience();

  const markers = {
    day: { default: marker_day, active: marker_day_active },
    night: { default: marker_night, active: marker_night_active },
  };

  const removeAttribution = (map) => {
    map.attributionControl._container.style = 'display: none';
  }

  const marker = (active) =>
    new L.Icon({
      iconUrl: markers[theme][active ? "active" : "default"],
      popupAnchor: [-0, -0],
      iconSize: [32, 45],
      iconAnchor: [15, 45],
    });

  return (
    <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
      {active && (
        <div style={{ width: "100%", zIndex: 2, flex: 0 }}>
          <Player spot={active} />
        </div>
      )}
      <div style={{flex: 1}}>
        <MapContainer
          style={{ height: "100%", zIndex: 0, background: "black" }}
          bounds={spots && spots.map((s) => [s.lat, s.lon])}
          zoom={50}
          scrollWheelZoom={false}
          zoomControl={false}
          dragging={false}
          whenCreated={removeAttribution}
        >
          <InvalidateSize active={active}/>

          {theme === "night" ? (
            <>
              <Tile theme="day" />
              <Tile theme="night" />
            </>
          ) : (
            <>
              <Tile theme="night" />
              <Tile theme="day" />
            </>
          )}

          {spots && spots.map((spot) => (
            <Marker
              key={spot.id}
              icon={marker(active && spot.id === active.id)}
              position={[spot.lat, spot.lon]}
            />
          ))}
        </MapContainer>
      </div>
    </div>
  );
}

function InvalidateSize({ active }) {
  const map = useMap();
  useEffect(() => {
    map && map.invalidateSize();
  }, [active, map]);

  return null;
}

function Tile({ theme }) {
  const map = useMap();
  useEffect(() => {
    const tiles = {
      day: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${STADIA_MAPS_API_KEY}`,
      },
      night: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=${STADIA_MAPS_API_KEY}`,
      },
    };
    const tile = tiles[theme];
    const offline = L.tileLayer
      .offline(tile.url, {
        attribution: tile.attribution,
        subdomains: PRODUCTION_URL,
        minZoom: 13,
      })
      .addTo(map);
    return () => offline.removeFrom(map);
  }, [theme, map]);
  return null;
}
